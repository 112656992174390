<template>
  <dashboard-card
    :title="$t('dashboard.titles.statistics')"
    :tooltip="$t('dashboard.paragraphs.statistics.delivered')"
    class="dashboard-statistics"
  >
    <div class="dashboard-statistics__wrapper tw-flex tw-flex-wrap">
      <dashboard-statistics-item
        v-for="(item, k) in items"
        :key="k"
        :icon="item.icon"
        :label="item.label"
        :unit="item.unit"
        :value="item.value"
        :tooltip="item.tooltip"
        :loading="$wait.is('fetching dashboard statistics')"
        data-test="item"
      />
    </div>

    <template #header-buttons>
      <ValidationProvider
        ref="date"
        :name="$t('dashboard.labels.statistics.range')"
      >
        <ctk-date-time-picker
          ref="range-input"
          id="range-input"
          v-model="date"
          name="range"
          :range="true"
          :label="$t('dashboard.labels.statistics.range')"
          :loader="$wait.is('fetching dashboard statistics')"
          :locale="$i18n.locale"
          :disabled="$wait.is('fetching dashboard statistics')"
          color="#287696"
          format="YYYY-MM-DD"
          formatted="ll"
          position="bottom"
          no-button-now
          no-shortcuts
          right
          data-test="datepicker"
          @is-shown="shown"
          @validate="updateStatistics"
          @input="resetStatistics"
        />
      </ValidationProvider>
    </template>
  </dashboard-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import { debounce } from 'underscore'
  import { defineComponent, computed } from '@vue/composition-api'
  // @ts-ignore
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

  import useCurrency from '@/composables/useCurrency'
  import useStore from '@/composables/useStore'
  import useI18n from '@/composables/useI18n'
  import Hotjar from '@/plugins/VueHotjar'

  import DashboardCard from './../DashboardCard/index.vue'
  import DashboardStatisticsItem from './_subs/DashboardStatisticsItem/index.vue'

  /**
   * @module component - DashboardStatistics
   */
  export default defineComponent({
    name: 'DashboardStatistics',
    components: {
      CtkDateTimePicker,
      DashboardCard,
      DashboardStatisticsItem
    },
    setup () {
      const i18n = useI18n()
      const store = useStore()

      const items = computed(() => {
        const metrics = store.value.getters['shipments/getDashboardMetrics']

        return [
          {
            icon: 'pallets',
            label: i18n.value.t('dashboard.titles.shipments'),
            value: metrics.shipment_count !== null ? i18n.value.n(metrics.shipment_count) : null
          },
          {
            icon: 'road',
            label: i18n.value.t('dashboard.titles.statistics.average_distance'),
            unit: i18n.value.t('app.labels.km_unit'),
            value: metrics.average_distance !== null ? i18n.value.n(metrics.average_distance) : null
          },
          {
            icon: 'weight',
            label: i18n.value.t('dashboard.titles.statistics.average_weight'),
            unit: i18n.value.t('app.labels.weight_unit'),
            value: metrics.average_weight !== null ? i18n.value.n(metrics.average_weight) : null
          },
          {
            icon: 'size',
            label: i18n.value.t('dashboard.titles.statistics.average_linear_meter'),
            value: metrics.average_linear_centimeters !== null ? i18n.value.n(metrics.average_linear_centimeters / 100) : null
          },
          {
            icon: 'budget',
            label: i18n.value.t('dashboard.titles.statistics.average_price'),
            unit: i18n.value.t('app.ht'),
            value: metrics.average_price !== null && metrics.average_price.amount !== null
              ? useCurrency(metrics.average_price.amount, metrics.average_price.currency, i18n.value.locale, true)
              : null
          },
          {
            icon: 'co2',
            label: i18n.value.t('dashboard.titles.statistics.carbon_footprint'),
            unit: i18n.value.t('app.labels.weight_unit'),
            value: metrics.co2_emissions !== null ? i18n.value.n(metrics.co2_emissions / 1000) : null,
            tooltip: i18n.value.t('app.paragraphs.carbon_footprint')
          }
        ]
      })

      return {
        items
      }
    },
    data () {
      return {
        /** @type {{ start: string|null, end: string|null }|null} */
        date: null
      }
    },
    methods: {
      ...mapActions('shipments', [
        'retrieveDashboardMetrics'
      ]),
      /**
       * @function fetchStatistics
       * @param {string|null} start
       * @param {string|null} end
       * @returns {Promise<any>}
       */
      fetchStatistics (start, end) {
        // @ts-ignore
        return this.retrieveDashboardMetrics({
          start,
          end
        })
          .catch(() => {})
      },
      /**
       * @function resetStatistics
       * @param {{ start: string|null, end: string|null }|null} value
       */
      resetStatistics (value) {
        if (value) return

        if (this.$matomo) {
          this.$matomo.trackEvent('Dashboard', 'Clear Statistics Period')
        }

        return this.fetchStatistics(null, null)
      },
      shown: debounce(function () {
        // @ts-ignore
        if (this.$matomo) {
          // @ts-ignore
          this.$matomo.trackEvent('Dashboard', 'Clicked Statistics Period')
        }

        Hotjar.tag('Clicked Statistics Period')
      }, 300),
      /**
       * @function updateStatistics
       */
      updateStatistics () {
        if (!this.date || (this.date && !this.date.start) || !this.date.end) return

        if (this.$matomo) {
          this.$matomo.trackEvent('Dashboard', 'Filter Statistics Period', [this.date.start, this.date.end].join('|'))
        }

        Hotjar.tag('Filter Statistics Period')

        return this.fetchStatistics(this.date.start, this.date.end)
          .catch(() => {})
          .finally(() => {
            this.$wait.end('fetching dashboard statistics')
          })
      }
    }
  })
</script>

<style lang="scss" scoped>
.dashboard-statistics__wrapper {
  margin: -0.5rem;
}
.dashboard-statistics .date-time-picker {
  min-width: 280px;
}
.dashboard-statistics .dashboard-statistics-item {
  flex: 1 0 calc(46% - 0.5rem);
  margin: 0.5rem;
}
@media (min-width: 770px) {
  .dashboard-statistics .dashboard-statistics-item {
    flex: 1 0 calc(31% - 0.5rem);
  }
}
@media (min-width: 1024px) {
  .dashboard-statistics .dashboard-statistics-item {
    flex: 1 0 calc(14% - 0.5rem);
  }
}
</style>

<style lang="scss">

  .dashboard-statistics .dashboard-card__header {
    margin-bottom: 20px;
  }

</style>
