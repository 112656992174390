<template>
  <dashboard-card
    class="dashboard-offers tw-relative"
  >
    <template #header-title>
      <div
        class="tw-w-full tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between"
      >
        <h2
          class="dashboard-card__header__title tw-mb-4 md:tw-mb-2"
          data-test="title"
        >
          <span
            v-text="$t('dashboard.titles.offers')"
            class="tw-mb-1"
            data-test="content"
          />
        </h2>

        <ui-button
          :rounded="false"
          variant="info"
          data-test="compare"
          v-matomo="{
            click: { category: 'Dashboard', action: 'Click Compare Offers' }
          }"
          @click="compare"
        >
          {{ $t('dashboard.buttons.offers.compare') }}
        </ui-button>
      </div>
    </template>

    <div class="tw-flex tw-flex-col md:tw-flex-row">
      <section
        class="dashboard-offers-presentation"
        data-test="offers-presentation"
      >
        <h3
          v-text="$t('dashboard.titles.offers.introduction')"
          class="dashboard-offers-presentation-title"
          data-test="offers-presentation-title"
        />
        <i18n
          tag="p"
          path="dashboard.paragraphs.offers.introduction.chronotruck"
          class="dashboard-offers-presentation-item"
          data-test="presentation-chronotruck"
        >
          <template #offer>
            <strong
              class="tw-font-medium"
              v-text="offers.chronotruck.title"
            />
          </template>
        </i18n>
        <i18n
          tag="p"
          path="dashboard.paragraphs.offers.introduction.pallet_network"
          class="dashboard-offers-presentation-item"
          data-test="presentation-pallet_network"
        >
          <template #offer>
            <strong
              class="tw-font-medium"
              v-text="offers.pallet_network.title"
            />
          </template>
        </i18n>
      </section>
      <dashboard-offers-item
        v-for="(offer, k) in offers"
        :key="k"
        :offer-type="k"
        :icon="offer.icon"
        :title="offer.title"
        :content="offer.content"
        :data-test="`${k}-item`"
        class="tw-w-full tw-mb-4"
        @learn-more="learnMore(k)"
      />
    </div>
  </dashboard-card>
</template>

<script>
  import { EventBus } from '@/services/EventBus'
  import { offers } from '@/../config/chronoinfos'

  import DashboardCard from './../DashboardCard'
  import DashboardOffersItem from './_subs/DashboardOffersItem'

  /**
   * @module component - DashboardOffers
   */
  export default {
    name: 'DashboardOffers',
    components: {
      DashboardCard,
      DashboardOffersItem
    },
    data () {
      return {
        offers: {
          chronotruck: {
            icon: require('@/assets/img/icons/truck-classic.svg'),
            title: offers.chronotruck,
            content: this.$t('dashboard.paragraphs.offers.chronotruck')
          },
          pallet_network: {
            icon: require('@/assets/img/icons/truck-network.svg'),
            title: offers.pallet_network,
            content: this.$t('dashboard.paragraphs.offers.pallet_network')
          }
        }
      }
    },
    methods: {
      /**
       * @function learnMore
       * @param {string} priceType
       */
      learnMore (priceType) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Dashboard', 'Click Learn More', priceType)
        }

        EventBus.$emit('dashboard:dialogs:open-dialog', {
          dialog: 'learnMore',
          priceType
        })
      },
      /**
       * @function compare
       */
      compare () {
        EventBus.$emit('dashboard:dialogs:open-dialog', {
          dialog: 'compare'
        })
      }
    }
  }
</script>

<style lang="scss">

  .dashboard-offers .dashboard-card__header {
    margin-bottom: 20px;
  }

  .dashboard-offers__content p {
    color: $secondary-text;
    margin-bottom: 0;
  }

</style>

<style lang="scss" scoped>
.dashboard-offers .dashboard-offers-presentation {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  line-height: 1.375;
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
  width: 100%;
}
@media (min-width: 770px) {
  .dashboard-offers .dashboard-offers-presentation {
    margin-right: 1.375rem;
    margin-bottom: 0px;
    width: 33.333333%;
  }
}
.dashboard-offers .dashboard-offers-presentation-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}
.dashboard-offers .dashboard-offers-presentation-title, .dashboard-offers .dashboard-offers-presentation-item, .dashboard-offers .dashboard-offers-presentation {
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
  text-align: left;
}
@media only screen and (min-width: $breakpoint-mobile-m) {
  .dashboard-offers .dashboard-offers-presentation {
    min-height: 420px;
  }
  .dashboard-offers .dashboard-offers-presentation::after {
    position: absolute;
    content: '';
    bottom: 16px;
    padding-bottom: min(calc((100% - 2.5rem) / 2.606061), 150px);
    width: calc(100% - 2.5rem);
    background-image: url('~@/assets/img/illustrations/offers-introduction.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: 0;
  }
}
@media only screen and (min-width: $breakpoint-tablet) {
  .dashboard-offers .dashboard-offers-item {
    width: 33.33%;
    margin-bottom: 0;
  }
  .dashboard-offers .dashboard-offers-item:not(:last-child) {
    margin-right: 1.375rem;
  }
}
</style>
